<template>
  <div class="index-content">
    <el-carousel indicator-position="inside">
      <el-carousel-item v-for="(item, index) in SliderContent" :key="index">
        <div class="home-content">
          <div class="text-content">
            <div class="text-area">
              <h1>{{ item.title }}</h1>
              <p>
                {{ item.detail }}
              </p>
              <MtButton
                :text="item.text"
                class="goWorld"
                radius="8"
                @click.native="changeSpace(index)"
              ></MtButton>
            </div>
            <div
              class="banner"
              :style="{ backgroundImage: `url(${item.img})` }"
            ></div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- <ShowImage></ShowImage> -->
    <ShowProduct></ShowProduct>
    <ShowSolution></ShowSolution>
    <ShowPedestal></ShowPedestal>
    <ShowAchievement></ShowAchievement>
    <MtData></MtData>
    <ShowTrust></ShowTrust>
  </div>
</template>
<script>
import ShowImage from "./components/show-image/index.vue";
import ShowProduct from "./components/show-product/index.vue";
import ShowSolution from "./components/show-solution/index.vue";
import ShowPedestal from "./components/show-pedestal/index.vue";
import ShowTeam from "./components/show-team/index.vue";
import ShowFramework from "./components/show-framework/index.vue";
import ShowAchievement from "./components/show-achievement/index.vue";
import ShowTrust from "./components/show-trust/index.vue";
import MtData from "./components/mt-data/index.vue";
import MtButton from "../../../../components/mt-button/index.vue";
export default {
  components: {
    ShowImage,
    ShowProduct,
    ShowSolution,
    ShowPedestal,
    ShowTeam,
    ShowFramework,
    ShowAchievement,
    ShowTrust,
    MtData,
    MtButton,
  },
  methods: {
    init() {
      console.log("do something");
    },
    changeSpace(index) {
      if (index == 0) {
        location.href = location.origin + "/linkspace/";
      } else {
        // this.$router.push({ name: "workspace" });
        location.href = location.origin + "/workspace/";
      }
    },
  },
  data() {
    return {
      SliderContent: [
        {
          title: "灵思" + "\xa0\xa0\xa0" + "元宇宙数字底座",
          detail: "打造实用的用户元宇宙空间",
          text: "进入世界",
          img: require("@/assets/index-bg.png"),
        },
        {
          title: "灵点" + "\xa0\xa0\xa0" + "数字孪生设计引擎",
          detail: "用于构架数字孪生场景 ，数字孪生内容一站式生产平台",
          text: "立即试用",
          img: require("@/assets/index-bg1.png"),
        },
        {
          title: "灵秀" + "\xa0\xa0\xa0" + "元宇宙数字化营销工具",
          detail: "用场景和数据，让好产品自己说话",
          text: "立即试用",
          img: require("@/assets/index-bg2.png"),
        },
      ],
    };
  },
  directives: {
    "lazy-component": {
      bind(el, binding) {
        if ("IntersectionObserver" in window) {
          let observer = new IntersectionObserver(
            (entries) => {
              const isIntersected = entries.some(
                (entry) => entry.intersectionRatio > 0
              );
              if (isIntersected) {
                // 执行绑定的事件
                binding.value();

                // 停止观察
                observer.unobserve(el);

                // 关闭观察器
                observer.disconnect();
              }
            },
            {
              threshold: [0, 0.25, 0.5, 0.75, 1],
            }
          );
          observer.observe(el);
        } else {
          binding.value();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.el-carousel__container {
  height: 928px;
  button {
    display: none;
  }
}
.el-carousel__button {
  background: #357aff;
  border-radius: 4px;
  width: 60px;
}
</style>
<style lang="scss" scoped>
.home-content {
  width: 100%;
  height: 928px;
  background-size: cover;
  // background-attachment: fixed;
  background-position: center 0;
  color: #333439;
  background-image: url("../../../../assets/banner-bg.png");
  .text-content {
    position: relative;
    display: flex;
    // align-items: center;
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    .text-area {   
      margin-top: 331px;  
      h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 70px;
        white-space: nowrap;
      }
      p {
        margin-top: 28px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }
      .goWorld {
        margin-top: 87px;
      }
    }
    //666*928
    .banner {
      position: absolute;
      left: 580px;
      width: 666px;
      height: 100%;
      background-size: cover;
    }
  }
}
.home-text2,
.home-text {
  color: white;
  position: absolute;
  font-size: 64px;
  font-weight: 700;
}
.home-text {
  top: 249px;
  left: 405px;
}
.home-text2 {
  top: 580px;
  left: 778px;
  .text-line {
    margin-right: 20px;
  }
}
.home-button {
  position: absolute;
  top: 780px;
  right: 408px;
}
.triange {
  width: 20px;
  position: absolute;
  top: 392px;
  right: 432px;
  .home-img {
    width: 20px;
    margin-bottom: 4px;
    height: 17px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.tuoyuan-foot {
  width: 100vw;
  height: 90px;
  background: white;
  overflow: hidden;
  .tuoyuan-content {
    width: 2125px;
    height: 300px;
    background-color: #333439;
    border-radius: 50%;
    position: relative;
    left: -102px;
    top: -210px;
  }
}
</style>
