<template>
  <div class="backColor">
    <div class="show-product w1120">
      <div class="top">
        <line-title
          :title="`数字孪生底层工具及衍生`"
          :color="`#333439`"
          :lineColor="`#C4C4C4`"
        ></line-title>
      </div>
      <div class="subheading">
        <p>
          打造高效数字孪生协同平台，为客户提供快速构建数字孪生管理平台的最优选择
        </p>
        <p>
          推动数字孪生可视化的进程，让数字孪生的红利真正普惠千行百业
        </p>
      </div>
      <div class="content">
        <div class="product-item">
          <div class="product-box">
            <div class="product-cricle">
              <img src="@/assets/homeImage/ling-pic.png" alt="" />
            </div>
            <img
              class="title-img"
              src="@/assets/homeImage/product-1.png"
              alt=""
            />
            <h2>灵思</h2>
            <p class="first-p">零探科技元宇宙社交平台</p>
            <p class="display-p">...</p>
            <p class="auto-p">使用者拥有的唯一虚拟ID，以独特身份在</p>
            <p class="auto-p">虚拟世界交流互动，打造实用元宇宙空间</p>
            <MtButton
              text="了解更多"
              width="308"
              height="48"
              radius="8"
              fontSize="14"
              class="product-button"
              @click.native="toProduct(0)"
            ></MtButton>
          </div>
        </div>
        <div class="product-item" style="margin: 0 54px;">
          <div class="product-box">
            <div class="product-cricle">
              <img src="@/assets/homeImage/ling-pic1.png" alt="" />
            </div>
            <img
              class="title-img"
              src="@/assets/homeImage/product-2.png"
              alt=""
            />
            <h2>灵点</h2>

            <p class="first-p">
              零探自研数字孪生设计引擎
            </p>
            <p class="display-p">...</p>
            <p class="auto-p">高效赋能团队协同开发，提供数字孪生应</p>
            <p class="auto-p">用方案，快速构建3D场景和可视化模型</p>
            <MtButton
              text="了解更多"
              width="308"
              height="48"
              radius="8"
              fontSize="14"
              class="product-button"
              @click.native="toProduct(1)"
            ></MtButton>
          </div>
        </div>
        <div class="product-item">
          <div class="product-box">
            <div class="product-cricle">
              <img src="@/assets/homeImage/ling-pic2.png" alt="" />
            </div>
            <img
              class="title-img"
              src="@/assets/homeImage/product-3.png"
              alt=""
            />
            <h2>灵秀</h2>
            <p class="first-p">
              零探轻量化数字孪生营销工具
            </p>
            <p class="display-p">...</p>
            <p class="auto-p">以3D场景还原用户真实场景，动态数据结</p>
            <p class="auto-p">合特定场景进行表达，让好产品自己说话</p>
            <MtButton
              text="了解更多"
              width="308"
              height="48"
              radius="8"
              fontSize="14"
              class="product-button"
              @click.native="toProduct(2)"
            ></MtButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MtButton from "../../../../../../components/mt-button/index.vue";
export default {
  components: { MtButton },
  methods: {
    toProduct(index) {
      switch (index) {
        case 0:
          this.$router.push({ path: "/productlinkMior" });
          break;
        case 1:
          this.$router.push({ path: "/productlinkPoint" });
          break;
        case 2:
          this.$router.push({ path: "/productlinkShow" });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show-product {
  height: 928px;
  .top {
    padding-top: 85px;
  }
  .subheading {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 72px;
    margin-top: 24px;
    margin-bottom: 173px;
    p {
      width: 738px;
      font-size: 16px;
      color: rgba(51, 52, 57, 0.8);
      text-align: center;
      line-height: 28px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    .product-item {
      width: 340px;
      .product-box {
        transition: height 0.3s;
        width: 100%;
        height: 300px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background-color: #ffffff;
        box-shadow: -16px -16px 40px #ffffff,
          16px 4px 64px rgba(18, 61, 101, 0.2), inset -8px -6px 80px #ffffff;
        position: relative;
        padding-top: 87px;
        &:hover .product-button,
        &:hover .auto-p {
          opacity: 1;
        }
        &:hover .display-p {
          display: none;
        }
        &:hover .title-img {
          display: none;
        }
        .first-p {
          font-weight: 400;
          font-size: 18px;
          line-height: 37px;
          color: #7886a1;
          margin-bottom: 8px;
        }
        .auto-p {
          opacity: 0;
          font-size: 14px;
          color: #7886a2;
          line-height: 22px;
          margin-bottom: 8px;
        }
        .product-button {
          transition: opacity 0.3s;
          opacity: 0;
          // margin: 0 auto;
          position: absolute;
          bottom: 48px;
          z-index: 1;
        }
        h2 {
          text-align: center;
          font-weight: 400;
          font-size: 24px;
          line-height: 44px;
          color: #3c3d3f;
          margin-bottom: 24px;
        }
        p {
          color: #7886a1;
        }
        .title-img {
          width: 234px;
          height: 225px;
          position: absolute;
          z-index: 0;
          top: 40px;
          left: calc(50% - 112px);
        }
        .product-cricle {
          position: relative;
          width: 122px;
          height: 122px;
          position: absolute;
          background: black;
          border-radius: 50%;
          top: -61px;
          left: calc(50% - 61px);
          background: #ffffff;
          box-shadow: -16px -16px 40px #ffffff,
            16px 4px 64px rgba(18, 61, 101, 0.1);
          img {
            width: 89px;
            height: 89px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .product-item:hover .product-box {
    height: 389px;
  }
}
</style>
