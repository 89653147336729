<template>
  <div class="show-solution w1120">
    <div class="top">
      <line-title :title="`解决方案`" :color="`#333439`"></line-title>
    </div>
    <div class="subheading">
      <p>
        无锡零探数字能源科技有限公司，致力于数字孪生技术领域的三维可视化研发和应用，将真实世界场景数字化
      </p>
    </div>
    <div class="content">
      <div class="left">
        <h2>
          {{ textTitle }}
        </h2>
        <p v-html="textContent"></p>
        <MtButton
          text="了解更多"
          width="309"
          height="48"
          radius="8"
          fontSize="14"
          class="more-button"
          @click.native="toSolution"
        ></MtButton>
      </div>
      <div class="right">
        <div class="one">
          <div
            class="card"
            :class="selectIndex === index ? 'select' : ''"
            v-for="(item, index) in oneList"
            @mouseover="changeIndex(index)"
          >
            <h2>{{ item.name }}</h2>
            <p v-show="selectIndex != index">{{ item.text }}</p>
          </div>
        </div>
        <div class="two">
          <div
            class="card"
            v-for="(item, index) in twoList"
            :class="selectIndex === index + 2 ? 'select' : ''"
            @mouseover="changeIndex(index + 2)"
          >
            <h2>{{ item.name }}</h2>
            <p v-show="selectIndex != index + 2" v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MtButton from "../../../../../../components/mt-button/index.vue";
export default {
  components: { MtButton },
  data() {
    return {
      oneList: [
        {
          name: "数字孪生解决方案",
          text: "虚实映射，让数字可见，让未来可现",
          content:
            "自研LINK-POINT开发平台，拥有功能强大的3D场景编辑器、资产管理库和功能数据库。<br/>支持多人实时协同、互联使用、数据接口模块化提供数字化转型解决方案及供应服务，构建数字蓝图",
          params: "1",
        },
        {
          name: "展厅解决方案",
          text: "全方位多媒体数字交互设计施工一体化载体",
          content:
            "聚焦产业科技馆、城运中心、智慧工厂驾驶舱，互动科普展馆、多媒体党建馆等细分领域，搭建各类展示载体。以设计施工为躯壳，以多媒体内容为灵魂，为客户搭建线上线下展示载体",
          params: "11",
        },
      ],
      twoList: [
        {
          name: "虚拟现实解决方案",
          text:
            "以VR技术全面打造虚拟的动态沉浸式场景<br/>实现沉浸式营销解决方案",
          content:
            "定位全球市场和全硬件平台策略，以VR技术作为核心竞争力，以VR为载体开发的链接物理世界的入口 将数字世界与虚拟世界相结，将场景从线下拓展到线上，全方位打造元宇宙时代的虚拟现实解决方案",
          params: "9",
        },
        {
          name: "数字人解决方案",
          text: "以虚拟人代替真人承载流量，塑造IP",
          content:
            "虚拟人的打造可以流量聚集、形象不易崩塌、技术玩法新颖，可以在众多场合出现，是元宇宙的核心组成部分，将是企业数字化转型的重要抓手之一，成为企业的重要数字化资产之一。虚拟人的出现能让政府、企业等有着一位永不凋零的宣传员",
          params: "7",
        },
      ],
      selectIndex: 0,
    };
  },
  methods: {
    changeIndex(index) {
      this.selectIndex = index;
    },
    toSolution() {
      this.$router.push({
        name: "assets-wisdomcity",
        params: { id: this.allList[this.selectIndex].params },
      });
    },
  },
  computed: {
    textTitle() {
      let list = this.oneList.concat(this.twoList);
      return list[this.selectIndex].name;
    },
    textContent() {
      let list = this.oneList.concat(this.twoList);
      return list[this.selectIndex].content;
    },
    allList() {
      return this.oneList.concat(this.twoList);
    },
  },
};
</script>
<style lang="scss" scoped>
.show-solution {
  height: 928px;
  .top {
    padding-top: 85px;
  }
  .subheading {
    height: 28px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 144px;
    p {
      font-size: 16px;
      color: rgba(51, 52, 57, 0.8);
      text-align: center;
      line-height: 28px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    .left {
      margin-right: 69px;
      .more-button {
        margin-top: 55px;
      }
      h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 41px;
        color: #333439;
        margin-bottom: 24px;
      }
      p {
        width: 338px;
        font-weight: 350;
        font-size: 16px;
        color: #5c5b5b;
        line-height: 28px;
      }
    }
    .right {
      display: flex;
      width: 680px;
      flex-wrap: wrap;
      .one {
        margin-right: 40px;
        margin-top: -37px;
      }
      .card {
        cursor: pointer;
        transition: all 0.4s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 320px;
        padding-left: 30px;
        padding-right: 10px;
        height: 150px;
        margin-top: 48px;
        box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8),
          16px 4px 64px rgba(18, 61, 101, 0.2);
        border-radius: 8px;
        h2 {
          font-weight: 500;
          font-size: 22px;
          color: #357aff;
          line-height: 44px;
        }
        p {
          font-weight: 350;
          font-size: 14px;
          line-height: 25px;
          color: #5c5b5b;
          margin-top: 6px;
        }
      }
      .select {
        background: #357aff;
        h2 {
          color: #ffffff;
        }
      }
      .two {
        padding-top: 19px;
      }
    }
  }
}
</style>
